import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="News" />
    <h1>Unsere Auftritte und Termine</h1>

    <p>Wir spielen überwiegend auf privaten Veranstaltungen.</p>
    <h2>Hier folgen einige Orte, an denen wir schon öffentlich aufgetreten sind</h2>
    <ul>
      <li>Jazz-Frühschoppen mit Flohmarkt auf dem Dorfplatz Lemsahl</li>
      <li> Im Rahmen des "Sonnenblumenfestes Frahmredder" mit einem Jazzfrühschoppen in Hamburg-Poppenbüttel.</li>
      <li>Konzert-Auftritt im Rahmen der wöchentlichen Kurkonzerte im Kurhaus von Bad Bevensen</li>
      <li>Gasthaus Quellenhof im Rodenbeker Quellental</li>
      <li>Geburtstagsfeier auf der `PASSAT`, Travemünde</li>
      <li>2017 u. 2018 Tönning/Eiderstedt – große Grillparty mit Live-Musik</li>
    </ul>
    <p style={{ fontWeight: "bold" }}>Wir freuen uns auf Sie!</p>
  </Layout>
)

export default SecondPage
